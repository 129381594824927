<template>
  <div>
    <b-card>
      <b-form-group
        label="Sekme Başlık"
        label-for="meta_title"
      >
        <validation-provider
          #default="{ errors }"
          name="Sekme Başlık"
          rules="required"
        >
          <b-form-input
            id="meta_title"
            v-model="itemData.meta_title"
            placeholder="Sekme Başlık"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Sayfa Başlık"
        label-for="heading_title"
      >
        <validation-provider
          #default="{ errors }"
          name="Sayfa Başlık"
          rules="required"
        >
          <b-form-input
            id="heading_title"
            v-model="itemData.heading_title"
            placeholder="Sayfa Başlık"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Sayfa Alt Başlık"
        label-for="heading_text"
      >
        <validation-provider
          #default="{ errors }"
          name="Sayfa Alt Başlık"
          rules="required"
        >
          <b-form-input
            id="heading_text"
            v-model="itemData.heading_text"
            placeholder="Sayfa Alt Başlık"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Sıra No"
        label-for="ordering"
      >
        <validation-provider
          #default="{ errors }"
          name="Sıra No"
          rules="required"
        >
          <b-form-input
            id="ordering"
            v-model="itemData.ordering"
            placeholder="Sıra"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Açıklama"
        label-for="meta_desc"
      >
        <b-form-textarea
          id="meta_desc"
          v-model="itemData.meta_desc"
          placeholder="Açıklama"
        />
        <div class="text-muted mt-1">
          Açıklama Uzunluğu: {{ itemData.meta_desc? itemData.meta_desc.length : 0 }} / 155
          <div class="font-small-2 text-info">
            Önerilen Uzunluk: 155 karakter
          </div>
        </div>
      </b-form-group>
      <b-form-group
        label="Anahtar Kelimeler"
        label-for="meta_keywords"
      >
        <b-form-textarea
          id="meta_keywords"
          v-model="itemData.meta_keywords"
          placeholder="Anahtar Kelimeler"
        />
        <div class="text-muted mt-1">
          Anahtar Kelime: {{ itemData.meta_keywords? itemData.meta_keywords.length : 0 }} / 260
          <div class="font-small-2 text-info">
            Önerilen Uzunluk: 260 karakter
          </div>
        </div>
      </b-form-group>
    </b-card>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ComponentForm',
  components: {
    ValidationProvider,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      imageUrl: this.$store.state.app.baseURL,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['components/dataItem']
    },
  },
}
</script>
