var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-form-group',{attrs:{"label":"Sekme Başlık","label-for":"meta_title"}},[_c('validation-provider',{attrs:{"name":"Sekme Başlık","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meta_title","placeholder":"Sekme Başlık"},model:{value:(_vm.itemData.meta_title),callback:function ($$v) {_vm.$set(_vm.itemData, "meta_title", $$v)},expression:"itemData.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Sayfa Başlık","label-for":"heading_title"}},[_c('validation-provider',{attrs:{"name":"Sayfa Başlık","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"heading_title","placeholder":"Sayfa Başlık"},model:{value:(_vm.itemData.heading_title),callback:function ($$v) {_vm.$set(_vm.itemData, "heading_title", $$v)},expression:"itemData.heading_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Sayfa Alt Başlık","label-for":"heading_text"}},[_c('validation-provider',{attrs:{"name":"Sayfa Alt Başlık","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"heading_text","placeholder":"Sayfa Alt Başlık"},model:{value:(_vm.itemData.heading_text),callback:function ($$v) {_vm.$set(_vm.itemData, "heading_text", $$v)},expression:"itemData.heading_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Sıra No","label-for":"ordering"}},[_c('validation-provider',{attrs:{"name":"Sıra No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ordering","placeholder":"Sıra"},model:{value:(_vm.itemData.ordering),callback:function ($$v) {_vm.$set(_vm.itemData, "ordering", $$v)},expression:"itemData.ordering"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Açıklama","label-for":"meta_desc"}},[_c('b-form-textarea',{attrs:{"id":"meta_desc","placeholder":"Açıklama"},model:{value:(_vm.itemData.meta_desc),callback:function ($$v) {_vm.$set(_vm.itemData, "meta_desc", $$v)},expression:"itemData.meta_desc"}}),_c('div',{staticClass:"text-muted mt-1"},[_vm._v(" Açıklama Uzunluğu: "+_vm._s(_vm.itemData.meta_desc? _vm.itemData.meta_desc.length : 0)+" / 155 "),_c('div',{staticClass:"font-small-2 text-info"},[_vm._v(" Önerilen Uzunluk: 155 karakter ")])])],1),_c('b-form-group',{attrs:{"label":"Anahtar Kelimeler","label-for":"meta_keywords"}},[_c('b-form-textarea',{attrs:{"id":"meta_keywords","placeholder":"Anahtar Kelimeler"},model:{value:(_vm.itemData.meta_keywords),callback:function ($$v) {_vm.$set(_vm.itemData, "meta_keywords", $$v)},expression:"itemData.meta_keywords"}}),_c('div',{staticClass:"text-muted mt-1"},[_vm._v(" Anahtar Kelime: "+_vm._s(_vm.itemData.meta_keywords? _vm.itemData.meta_keywords.length : 0)+" / 260 "),_c('div',{staticClass:"font-small-2 text-info"},[_vm._v(" Önerilen Uzunluk: 260 karakter ")])])],1)],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Kaydet ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }